.score-table {
  max-width: 800px;
  margin: 0 auto;
  th {
    background-color: var(--bs-gray-100);
    padding: 10px;
    font-weight: 600;
    vertical-align: middle;
    &:first-child {
      padding: 10px;
    }
  }
  td {
    width: 100px;
    input {
      width: 100%;
    }
    &:first-child {
      padding: 10px;
    }
  }
}

.competency-cut-container {
  margin-bottom: 32px;
  border: solid 1px var(--bs-gray-400);
  border-radius: 6px;
  padding: 20px;
  position: relative;

  .competency-title {
    position: absolute;
    top: -12px;
    left: 20px;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: white;
    padding: 0 4px;
  }
  .save-btn {
    position: absolute;
    top: -18px;
    right: 14px;
  }
}

.react-datetime-picker__wrapper {
  border: solid 1px var(--bs-gray-400);
  border-radius: 6px;
  padding: 0.75rem 1rem;
}
